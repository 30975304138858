
import { defineComponent, ref, computed, inject } from 'vue';

import { Pokemon, Ability } from '@/models/Pokemon';

export default defineComponent({
  props: {
    pokemon: {
      type: Object,
      required: true,
    }
  },
  setup(props){
    const pokemon = props.pokemon as Pokemon;

    const pokedexNum = ref<number>(pokemon.id);
    const height = ref<number>(pokemon.height / 10);
    const weight = ref<number>(pokemon.weight / 10);
    const abilities = ref<Ability[]>(pokemon.abilities);
    const types = computed<string[]>(() => pokemon.types.map(typeSlot => typeSlot.type.name));
    
    const typeIconsFolder = inject<(path: string) => string>('typeIconsFolder');
    const loadTypeImg = (type: string) => typeIconsFolder ? typeIconsFolder(`./${type}.svg`) : '';

    return {
      pokedexNum,
      types,
      height,
      weight,
      abilities,
      loadTypeImg,
    };
  },
});
