
import { Pokemon } from '@/models/Pokemon';
import { PokemonSpecies } from '@/models/PokemonSpecies';
import { defineComponent, onMounted, ref, reactive, computed } from 'vue';

interface Gender {
  male: number;
  female: number;
}

export default defineComponent({
  props: {
    pokemon: {
      type: Object,
      required: true,
    }
  },
  setup(props){
    const pokemon = props.pokemon as Pokemon;
    const eggGroups = ref<string[]>([]);
    const hatchingSteps = ref<number>(0);
    const gender = reactive<Gender>({
      male: 0,
      female: 0
    });

    let species: PokemonSpecies;

    onMounted(async () => {
      await getSpecies();

      eggGroups.value = getEggGroups();
      hatchingSteps.value = 255 * (species.hatch_counter + 1);
      getGender();
    })

    async function getSpecies() {
      const data = await fetch(pokemon.species.url);
      species = await data.json() as PokemonSpecies;
    }

    const getEggGroups = () => species.egg_groups.map(eggGroup => eggGroup.name.replace('-', ' '));
    const getGender = () => {
      gender.female = (species.gender_rate / 8) * 100;
      gender.male = 100 - gender.female;
    }

    return {
      eggGroups,
      gender,
      hatchingSteps
    };
  },
});
