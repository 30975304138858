
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pokemonName: {
      type: String,
      required: true,
      default: 'all Pokemon',
    }
  },
})
