
import { EvolutionChain } from '@/models/EvolutionChain';
import { Pokemon } from '@/models/Pokemon';
import { PokemonSpecies } from '@/models/PokemonSpecies';
import { defineComponent, onMounted, inject, ref } from 'vue';
import { useStore } from 'vuex';

interface Ring {
  id: number;
  name: string;
  types: string[],
  artwork: string,
  hasNext: boolean;
}

export default defineComponent({
  props: {
    species: {
      type: Object,
      required: true,
    },
  },
  setup(props){
    const store = useStore();
    const species = props.species as PokemonSpecies;
    const typeIconsFolder = inject<(path: string) => string>('typeIconsFolder');
    const evoRings = ref<Ring[]>([]);

    onMounted(async () => {
      const evoLine = await getEvoLine(species.evolution_chain.url) as EvolutionChain;
      evoRings.value = await calcEvoRingsData(evoLine);
    });

    async function getEvoLine(url: string) {
      const data = await fetch(url);
      return await data.json() as EvolutionChain;
    }
    async function calcEvoRingsData(evoLine: EvolutionChain) {
      let currentRing = evoLine.chain;
      let numOfEvos = currentRing.evolves_to.length;
      let rings: Ring[] = [];

      do {
        const ringPokemon = await store.dispatch("getPokemonByName", currentRing.species.name) as Pokemon;
        const hasNext = !(currentRing.evolves_to.length === 0);
        
        rings.push({
          id: ringPokemon.id,
          name: ringPokemon.name,
          types: ringPokemon.types.map(slot => slot.type.name),
          artwork: ringPokemon.sprites.other['official-artwork'].front_default,
          hasNext,
        });

        //? If the number of evolution of the current pokemon is > 1, then the next currentRing
        //? will be that one which has the same name as the pokemon in the details page
        //? es. Details page of Sylveon (https://.../pokemon-list/sylveon)
        //?     eevee --> sylveon
        if (numOfEvos > 1)
          currentRing = currentRing.evolves_to.filter(evo => evo.species.name === species.name)[0];
        else 
          currentRing = currentRing.evolves_to[0];

        numOfEvos = currentRing ? currentRing.evolves_to.length : 0;
      } while(currentRing);

      return rings;
    }
    const loadTypeImg = (type: string) => typeIconsFolder ? typeIconsFolder(`./${type}.svg`) : '';

    return {
      evoRings,
      loadTypeImg,
    };
  },
});
