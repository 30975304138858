
import { Move } from '@/models/Move';
import { MoveSource } from '@/models/Pokemon';
import { MoveRecord } from '@/models/class/MoveRecord';
import { Machine } from '@/models/Machine';
import { defineComponent, ref, onMounted, inject } from 'vue';

interface IMoveRecord {
  accuracy: number;
  power: number;
  category: string;
  name: string;
  type: string;
  lvl?: number;
  tmNum?: number;
  trNum?: number;
}

export default defineComponent({
  props: {
    tableLable: {
      type: String,
      required: true,
    },
    moves: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'default'
    }
  },
  setup(props){
    const moves = props.moves as MoveSource[];
    const tableMoves = ref<IMoveRecord[]>([]);
    const typeIconsFolder = inject<(path: string) => string>('typeIconsFolder');
    
    onMounted(() => {
      moves.forEach(async (moveSrc) => {
        const moveDetails = await getMoveDetails(moveSrc.move.url);

        let tableMove = new MoveRecord(0, 0, '', '', '');
        tableMove.accuracy = moveDetails.accuracy;
        tableMove.power = moveDetails.power;
        tableMove.category = moveDetails.damage_class.name;
        tableMove.name = moveDetails.name.replace('-', ' ');
        tableMove.type = moveDetails.type.name;

        switch(moveSrc.version_group_details[0].move_learn_method.name) {
          case 'level-up':
            tableMove.lvl = moveSrc.version_group_details[0].level_learned_at;
            break;

          case 'machine':
            tableMove.tmNum = await getMachineNum(moveDetails.machines[0].machine.url);
            break;
        }

        tableMoves.value.push(tableMove);
      });

      tableMoves.value.sort((current, next) => current.name.localeCompare(next.name));
    });

    async function getMoveDetails(moveUrl: string) {
      const data = await fetch(moveUrl);
      return await data.json() as Move;
    }

    async function getMachineNum(machineUrl: string) {
      const data = await fetch(machineUrl);
      const machine = await data.json() as Machine;

      return parseInt(machine.item.name.substring(2));
    }
    const loadTypeImg = (type: string) => typeIconsFolder ? typeIconsFolder(`./${type}.svg`) : '';


    return {
      tableMoves,
      loadTypeImg
    };
  },
});
