
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';

import { IAPIResponse, Source } from '@/models/APIResponse';
import { Pokemon } from '@/models/Pokemon';
import PokemonCard from '@/components/PokemonCard.vue';

export default defineComponent({
  name: 'Home',
  components: {
    PokemonCard,
  },
  setup() {
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const pokedex = computed<Pokemon[]>(() => store.state.pokemon || []);
    const nextPageUrl = ref<string | null>(null);
    const prevPageUrl = ref<string | null>(null);

    let loaderTimeout = 3000;
    let response: IAPIResponse;

    onMounted(async () => {
      isLoading.value = true;
        
      response = await store.dispatch('getAllPokemon') as IAPIResponse;
      nextPageUrl.value = response.next;
      prevPageUrl.value = response.previous;

      loadPokemon(response.results);

      setTimeout(() => {
        isLoading.value = false;
        loaderTimeout /= 2; // half cuts the fake time needed to load the page
      }, loaderTimeout);
    });

    /**  Gets pokemon data and save it in store under *pokemon* variable */
    async function loadPokemon(data: Source[]) {
      // Fetch each pokemon data using its url
      const pokemon = await Promise.all(data.map(async _pokemon => await fetchPkmnData(_pokemon.url))) as Pokemon[];

      await store.commit('updatePokemon', pokemon);
    }

    async function fetchPkmnData(url: string) {
      const res = await fetch(url);
      return await res.json();
    }

    /** Called whenever the user clicks "Next" or "Previous" buttons. It returns the next X pokèmon */
    async function changePage(next = true) {
      isLoading.value = true;

      response = await store.dispatch('getAllPokemon', next ? response.next : response.previous) as IAPIResponse;
      nextPageUrl.value = response.next;
      prevPageUrl.value = response.previous;

      loadPokemon(response.results);
      setTimeout(() => isLoading.value = false, loaderTimeout);
    }

    return {
      isLoading,
      pokedex,
      changePage,
      nextPageUrl,
      prevPageUrl,
    };
  }
});
