
import { defineComponent, inject } from 'vue';

export default defineComponent({
  props: {
    pokemon: {
      type: Object,
      required: true,
    },
  },
  setup(){
    const typeIconsFolder = inject<(path: string) => string>('typeIconsFolder');
    const loadTypeImg = (type: string) => typeIconsFolder ? typeIconsFolder(`./${type}.svg`) : '';

    return {
      loadTypeImg,
    };
  },
});
