
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    stats: {
      type: Object,
      required: true,
    }
  },
});
