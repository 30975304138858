
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import PokedexData from '@/components/pokemon-details/PokedexData.vue';
import Breeding from '@/components/pokemon-details/Breeding.vue';
import Stats from '@/components/pokemon-details/Stats.vue';
import EvolutionLine from '@/components/pokemon-details/EvolutionLine.vue';
import MovesTable from '@/components/pokemon-details/MovesTable.vue';

import { MoveSource, Pokemon } from '@/models/Pokemon';
import { PokemonSpecies } from '@/models/PokemonSpecies';

export default defineComponent({
  components: {
    PokedexData,
    Breeding,
    Stats,
    EvolutionLine,
    MovesTable,
  },
  setup(){
    const route = useRoute();
    const store = useStore();
    const isLoading = ref<boolean>(false);
    const tmpPkmnName = ref<string>('');
    const language = "en";
    const pokemonArtworkUrl = ref<string>('');
    const pokemon = ref<Pokemon>();
    const species = ref<PokemonSpecies>();
    const genus = ref<string>('');
    const moves = ref<MoveSource[]>([]);

    let loaderTimeout = 1500;

    onMounted(async () => {
      isLoading.value = true;
      tmpPkmnName.value = route.params.name.toString();

      const allPokemon = store.state.pokemon as Pokemon[];

      // Check if store's pokemon is empty
      if (allPokemon.length !== 0) 
        pokemon.value = allPokemon.filter(p => p.name === route.params.name)[0];  // retrieve the specific pokemon from the list

      // If the list is empty or the wanted pokemon is not there, retrieve it by GET call
      if (pokemon.value === undefined)     
        pokemon.value = await store.dispatch('getPokemonByName', route.params.name);

      // Moves
      if(!pokemon.value) return;
      moves.value = pokemon.value.moves as MoveSource[];

      await getSpecies();
      getArtwork();

      if (!species.value) return;
      genus.value = species.value.genera.filter(g => g.language.name == language)[0].genus;

      setTimeout(() => isLoading.value = false, loaderTimeout);
    });

    function getArtwork() {
      if (!pokemon.value) return;

      // Get the official artwork
      const officialArtwork = pokemon.value.sprites.other['official-artwork'].front_default;

      // if it doesn't exists get the front_default sprite
      if(officialArtwork === '' || officialArtwork === undefined)
        pokemonArtworkUrl.value = pokemon.value.sprites.front_default;

      pokemonArtworkUrl.value = officialArtwork;
    }
    async function getSpecies() {
      if (!pokemon.value) return;

      const data = await fetch(pokemon.value.species.url);
      species.value = await data.json() as PokemonSpecies;
    }

    return {
      pokemon,
      isLoading,
      tmpPkmnName,
      pokemonArtworkUrl,
      species,
      genus,
      moves
    };
  },
});
